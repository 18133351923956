<template>
	<v-container class="mt-4 px-5" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title class="text-subtitle-1">
	  		    Mis permisos
	  		    <v-spacer></v-spacer>
	  		    <v-btn color="primary" small @click="dialog = true" rounded>Solicitar</v-btn>
	  		  </v-card-title>
	  		  <v-card-text>
					  <v-data-table
					    :headers="headers"
					    :items="permisos"
					    sort-by="calories"
					    class="elevation-0"
					  >

					  	<template v-slot:item.estatus="{ item }">
                <v-chip small v-if="!item.autorizo_jefe && !item.autorizo_rh">SIN REVISION</v-chip>
                <v-chip small v-else-if="item.autorizo_jefe == 1 || item.autorizo_rh == 1" color="green" dark>ACEPTADO</v-chip>
                <v-chip small v-else-if="item.autorizo_jefe == 2 || item.autorizo_rh == 2" color="red" dark>NO ACEPTADO</v-chip>
                <v-chip small v-else color="orange" dark>EN REVISION</v-chip>
              </template>

					    <template v-slot:item.actions="{ item }">
					      <v-icon
					        small
					        class="mr-2"
					        color="blue"
					        @click="editItem(item)"
					      >
					        mdi-eye
					      </v-icon>
					      <!-- <v-icon
					      	v-if="!item.autorizo_jefe && !item.autorizo_rh"
					        small
					        color="red"
					        @click="deleteItem(item)"
					      >
					        mdi-delete
					      </v-icon> -->
					    </template>
					    <template v-slot:no-data>
					      <v-btn
					        color="primary"
					        @click="initialize"
					      >
					        Reset
					      </v-btn>
					    </template>
					  </v-data-table>
	  		  </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <v-dialog
      v-model="dialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Folio: {{ editedItem.idsolicitud_permisos }}</span>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            dark
            rounded
            small
            icon
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="black--text" v-if="editedIndex >= 0">
        	<span><b>Solicitante: </b> {{ editedItem.solicitante }}</span>
        	<br/>
        	<span><b>Rango días:  </b> {{ editedItem.fecha_inicio }} a {{ editedItem.fecha_final }} </span>
        	<br/>
        	<span><b>Motivo:</b> {{ editedItem.motivo }}</span>
        	<br/>
        	<br/>
        	<br/>

        	<v-row>
        		<v-col cols="12" md="6">
        			<span><b>Jefe Directo:</b> {{ editedItem.jefe }}</span>
        			<br/>
        			<v-chip v-if="editedItem.autorizo_jefe == 1"  color="green" dark small>Autorizó</v-chip>
        			<v-chip v-else-if="editedItem.autorizo_jefe == 2" color="red"   dark small>Rechazó</v-chip>
        			<v-chip v-else color="grey"   dark small>Pendiente</v-chip>
        			<br/>
        			<span v-if="editedItem.motivo_rechazo_jefe"> {{ editedItem.motivo_rechazo_jefe }}</span>
        		</v-col>

        		<v-col cols="12" md="6">
        			<span><b>RH:</b> {{ editedItem.rh }}</span>
        			<br/>
        			<v-chip v-if="editedItem.autorizo_rh == 1"  color="green" dark small>Autorizó</v-chip>
        			<v-chip v-else-if="editedItem.autorizo_rh == 2" color="red"   dark small>Rechazó</v-chip>
        			<v-chip v-else color="grey"   dark small>Pendiente</v-chip>
        			<br/>
        			<span v-if="editedItem.motivo_rechazo_rh"> {{ editedItem.motivo_rechazo_rh }}</span>
        		</v-col>
        	</v-row>
        </v-card-text>

        <v-card-text class="black--text" v-if="editedIndex === -1">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="editedItem.fecha_inicio"
                  label="Fecha de inicio"
                  filled
                  type="date"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="editedItem.fecha_final"
                  label="Fecha final"
                  filled
                  type="date"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="editedItem.motivo"
                  label="Motivo"
                  filled
                  hide-details
                ></v-textarea>
              </v-col>
              <v-col cols="12">
  							<div v-if="!file">
			            <div :class="['dropZone', dragging ? 'dropZone-over' : '']" @dragenter="dragging = true" @dragleave="dragging = false">
			              <div class="dropZone-info" @drag="onChange">
			                <span class="fa fa-cloud-upload dropZone-title"></span>
			                <span class="dropZone-title blue--text"><b>Arrastre el archivo para cargar</b></span>
			                <div class="dropZone-upload-limit-info">
			                  <div>Extensión: png, jpg, jpeg, svg</div>
			                  <div>Tamaño máximo: 10 MB</div>
			                </div>
			              </div>
			              <input type="file" @change="onChange">
			            </div>
			          </div>  
			          <v-btn  v-else color="red" class="mb-2" dense @click="removeFile" fab small dark><v-icon>mdi-delete</v-icon></v-btn>
          			<v-img :src="vistaPrevia.url" v-if="vistaPrevia" contain max-height="300"/>            
              </v-col>
            </v-row>

            <v-row v-if="editedItem.archivo_adjunto != 'null'">
              <v-col cols="12">
                <v-img :src="url + editedItem.archivo_adjunto"/>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="red"
            dark
            rounded
            small
            @click="close"
          >
            Cerrar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
          	v-if="editedIndex === -1"
            color="primary"
            dark
            rounded
            small
            @click="save"
          >
            Solicitar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'
  import funcionesExcel  from '@/mixins/funcionesExcel'
  import leerImagenes    from '@/mixins/leerImagenes'


  export default {

  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel, leerImagenes ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'ID'             , value: 'idsolicitud_permisos', sortable: false },
        { text: 'Solicitante'    , value: 'solicitante' },
        // { text: 'RH'      , value: 'rh' },
        { text: 'Fecha'          , value: 'fecha_creacion' },
        { text: 'Estatus'        , value: 'estatus' },
        { text: 'Actions'        , value: 'actions', sortable: false },
      ],
      permisos: [],
      editedIndex: -1,
      editedItem: {
        idsolicitud_permisos: '',
        id_usuario: 0,
        idareas_ticket: 0,
        id_jefe_directo: 0,
        id_rh: 0,
        autorizo_jefe: 0,
        autorizo_rh: 0,
        fecha_inicio:0,
        fecha_final: 0,
        motivo: '',
        archivo_adjunto: 0,
        motivo_rechazo_jefe:'',
        motivo_rechazo_rh:''
      },
      defaultItem: {
        idsolicitud_permisos: '',
        id_usuario: 0,
        idareas_ticket: 0,
        id_jefe_directo: 0,
        id_rh: 0,
        autorizo_jefe: 0,
        autorizo_rh: 0,
        fecha_inicio:0,
        fecha_final: 0,
        motivo: '',
        archivo_adjunto: 0,
        motivo_rechazo_jefe:'',
        motivo_rechazo_rh:''
      },

      file: '',
      dragging: false,
      vistaPrevia: null,
      url:''

    }),

    computed: {
    	...mapGetters('login', ['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      console.log( this.getdatosUsuario.permiso )
      this.url = axios.defaults.baseURL + 'justificantes/'
      this.initialize()
    },

    methods: {
      initialize () {
        this.permisos = []
        this.cargar = true
        return this.$http.get('permisos.usuario/' + this.getdatosUsuario.iderp ).then(response=>{
        	this.permisos = response.data
        	this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.permisos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.permisos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.permisos.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        // Guardar imagen primero y retornar lo demás
        this.cargar = true
        let formData = new FormData();
        //se crea el objeto y se le agrega como un apendice el archivo
        formData.append("file", this.file);
        formData.append("data", this.editedItem);
        formData.append('idsolicitud_permisos',this.editedItem.idsolicitud_permisos)
        formData.append('id_usuario'          ,this.getdatosUsuario.iderp)
        formData.append('idareas_ticket'      ,this.getdatosUsuario.permiso && this.getdatosUsuario.permiso.idareas_ticket ? this.getdatosUsuario.permiso.idareas_ticket : 0 )
        formData.append('id_jefe_directo'     ,this.editedItem.id_jefe_directo)
        formData.append('id_rh'               ,this.editedItem.id_rh)
        formData.append('autorizo_jefe'       ,this.editedItem.autorizo_jefe)
        formData.append('autorizo_rh'         ,this.editedItem.autorizo_rh)
        formData.append('fecha_inicio'        ,this.editedItem.fecha_inicio)
        formData.append('fecha_final'         ,this.editedItem.fecha_final)
        formData.append('motivo'              ,this.editedItem.motivo)
        formData.append('archivo_adjunto'     ,this.editedItem.archivo_adjunto)
        formData.append('motivo_rechazo_jefe' ,this.editedItem.motivo_rechazo_jefe)
        formData.append('motivo_rechazo_rh'   ,this.editedItem.motivo_rechazo_rh)

        return this.$http.post('permisos.agregar', formData ).then(response=>{
        	this.file = null
        	this.initialize()
        	this.validarSuccess( response.data.message )
        	this.cargar = false
	        this.close()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },
    },
  }
</script>
<style scoped>
  .dropZone {
    width: 80%;
    height: 150px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px solid #D8D8D8;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #D8D8D8;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 80%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D8D8D8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }
</style>